<template>
  <vuestic-modal
    v-bind:large="true"
    ref="largeModalEdict"
    cancelText="Cancelar"
    okClass="none"
    cancelClass="none"
  >
    <span slot="title">{{grupo.nome}}</span>

    <el-input
      placeholder="Precione enter para adicionar um item"
      v-model="item.nome"
      v-on:change="cadastrarGrupo()"
    ></el-input>

    <el-table :data="itens">
      <el-table-column
        label="Itens"
        prop="nome"
      />
      <el-table-column>
        <template slot-scope="scope">

          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="deletarItem(scope.row.id,scope.$index)"
            circle
          />
        </template>
      </el-table-column>

    </el-table>
    <el-row
      class="mt-4"
      type="flex"
      justify="end"
    >

      <el-button
        @click="$refs.largeModalEdict.close()"
        type="success"
      >
        Salvar
      </el-button>
    </el-row>
  </vuestic-modal>
</template>

<script>
export default {
  props: {
    grupo: {
      type: Object,
      required: true,
    },
    itens: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      item: {
        nome: '',
        grupo: 0,
      },
    };
  },
  created() {
    // this.getItens();
  },

  methods: {
    async open() {
      this.item.nome = '';
      this.$refs.largeModalEdict.open();
    },
    getItens() {
      this.$axios.get('api/item/').then((res) => {
        this.itens = res.data;
      });
    },
    cadastrarGrupo() {
      this.item.grupo = this.grupo.id;
      this.$axios.post('api/item/', this.item).then((res) => {
        this.$emit('novoItem', res.data);
        this.itens.push(res.data);
        this.item.nome = '';
      });
    },
    deletarItem(id, index) {
      this.$axios.delete(`api/item/${id}/`).then(() => {
        this.itens.splice(index, 1);
      });
    },
  },
};
// / {} =
</script>

<style>
</style>
